import { forwardRef, useState } from 'react';
// @mui
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify/Iconify';
import Grid from '@mui/system/Unstable_Grid/Grid';

// ----------------------------------------------------------------------

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

export default function NeedHelpDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    display: 'flex',
    gap: '.4rem',
  };
  return (
    <div>
      <Button variant="text" color="inherit" onClick={handleClickOpen}>
        Need Help?
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          color="inherit"
          edge="end"
          style={{ placeSelf: 'end', marginRight: '4px' }}
          onClick={handleClose}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <DialogTitle id="alert-dialog-slide-title">Lets get in touch</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={1} mb={3}>
              <Grid xs={12} md={12}>
                <span style={styles}>
                  <Iconify icon="fa:mobile" />
                  +91-9898677811
                </span>
              </Grid>
              <Grid xs={12} md={12}>
                <span style={styles}>
                  <Iconify icon="ion:mail-outline" />
                  hello@1loyalty.ai
                </span>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
