import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { ILandingState } from 'src/redux/slices/landingParams';
import createRequest from 'src/service/apiService';
// utils
import { userMapper } from 'src/utils/constants';
import endpoints from 'src/utils/endpoints';
import axios from '../utils/axios';

import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession, setUserDetails } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(() => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const userDetailsString = storageAvailable ? localStorage.getItem('userDetails') : '';

      // const user: any = storageAvailable
      //   ? JSON?.parse(localStorage.getItem('userDetails') || '')
      //   : {};
      // if (accessToken && isValidToken(accessToken)) {
      if (accessToken && isValidToken(accessToken) && userDetailsString) {
        const user = JSON.parse(userDetailsString);
        setSession(accessToken);
        console.log('----- session is active -----');
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user: {
              displayName: user.userName,
              role: userMapper[user.role],
              programs: user.programs,
              clientCode: user.clientCode,
              storeCode: user.storeCode,
              clientName: user?.clientName,
              isCashierMenuEnabled: user?.isCashierMenuEnabled,
              txnSuccessCommType: user?.txnSuccessCommType,
              fraudDetectionCount: user?.fraudDetectionCount,
              clientConfig: user.clientConfig,
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string, commonQuery: ILandingState) => {
    const response = await axios.post(endpoints.login, {
      ...commonQuery,
      userName: email,
      password,
    });

    const {
      token,
      userName,
      role,
      permissions,
      programs,
      clientCode,
      storeCode,
      clientName,
      isCashierMenuEnabled,
      responseCode,
      txnSuccessCommType,
      fraudDetectionCount,
      clientConfig,
      mobileNo,
    } = response.data;
    if (responseCode === 200) {
      setSession(token);
      const user: any = {
        role,
        userName,
        permissions,
        programs,
        clientCode,
        storeCode,
        clientName,
        isCashierMenuEnabled,
        txnSuccessCommType,
        fraudDetectionCount,
        clientConfig,
        mobileNo,
      };
      setUserDetails(user);
      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            displayName: userName,
            role: userMapper[role],
            programs,
            clientCode,
            storeCode,
            clientName,
            isCashierMenuEnabled,
            txnSuccessCommType,
            fraudDetectionCount,
            clientConfig,
            mobileNo,
          },
        },
      });
    }

    return response.data;
  }, []);

  // REGISTER
  const register = useCallback(
    // async (requestDetail: any, commonQuery?: ILandingState, reqType: 'put' | 'post') => {
    async (requestDetail: any, commonQuery: any, reqType: 'put' | 'post') => {
      const response: any = await axios[reqType](endpoints.signup, {
        ...commonQuery,
        requestDetail,
      });
      return response;
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async (commonQuery: ILandingState) => {
    const response = await axios.post(endpoints.logout, {
      ...commonQuery,
    });
    // if (response.status === 200) {
      setSession(null);
      dispatch({
        type: Types.LOGOUT,
      });
    // }
  }, []);

  const getUIConfig = useCallback(
    async (commonQuery: ILandingState, handlerDispatch: any, hasErrorFetchingUIConfig: any) => {
      try {
        const config: any = {
          url: endpoints.getUIConfigApi,
          data: commonQuery,
          method: 'POST',
        };
        const response = await createRequest(config);
        if (response.status === 200) {
          handlerDispatch(response.data);
        }
      } catch (error) {
        dispatch(hasErrorFetchingUIConfig(error));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const loginWithOTP = useCallback((data: any) => {
    const { accessToken, userName, role } = data;
    setSession(accessToken);
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: { displayName: userName || 'admin', role },
      },
    });
  }, []);

  const loginWithInstrumentHandler = useCallback(
    // eslint-disable-next-line consistent-return
    async (data: any, action: any, selectedValue: any, tag: string) => {
      const response: any = await axios.post(endpoints.loginWithInstrument, data);
          const {
            token,
            userName,
            role,
            permissions,
            programs,
            clientCode,
            storeCode,
            clientName,
            isCashierMenuEnabled,
            responseCode,
            txnSuccessCommType,
            fraudDetectionCount,
            clientConfig,
            mobileNo,
          } = response.data;
      if (response.status === 200 && responseCode === 200) {
        sessionStorage.setItem(selectedValue, tag);
        sessionStorage.setItem('otpMode', selectedValue);
        setSession(token);
        const user: any = {
          role,
          userName,
          permissions,
          programs,
          clientCode,
          storeCode,
          clientName,
          isCashierMenuEnabled,
          txnSuccessCommType,
          fraudDetectionCount,
          clientConfig,
          mobileNo,
        };
        setUserDetails(user);
        if (action) {
          const act: string | null = sessionStorage.getItem('action');
          if (!act) {
            sessionStorage.setItem('action', action);
          }
        } else {
          sessionStorage.removeItem('action');
        }
        return response;
      }
    },
    []
  );

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      loginWithGoogle: () => {},
      loginWithGithub: () => {},
      loginWithTwitter: () => {},
      register,
      logout,
      getUIConfig,
      loginWithOTP,
      loginWithInstrumentHandler,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      getUIConfig,
      loginWithOTP,
      loginWithInstrumentHandler,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}