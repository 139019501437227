import { createSlice } from '@reduxjs/toolkit';
import { IUserAccount } from 'src/@types/user';

const initialState: IUserAccount = {
  membershipId: '',
  membershipStatus: '',
  firstName: '',
  lastName: '',
  emailId: '',
  mobileNo: '',
  address1: '',
  address2: '',
  address3: '',
  country: '',
  state: '',
  city: '',
  pincode: '',
  dateOfBirth: '',
  weddingAnniversary: '',
  otpVerified: 'No',
  status: '',
  memberType: '',
  emailVerified: '',
  avatarUrl: '',
  gender: '',
  role: '',
  userName: '',
  password: '',
  unitValue: '',
  client: '',
  programs: ''
};

const userInfoReducerSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    // START LOADING
    setUserInfo(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserInfo } = userInfoReducerSlice.actions;

// Reducer
export default userInfoReducerSlice;
