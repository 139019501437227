import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import { userRole } from 'src/utils/constants';
import { useAuthContext } from 'src/auth/useAuthContext';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
//
import { NavListProps } from '../types';
import NavItem from './NavItem';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  permissions?: string[];
};

export default function NavList({ data, depth, hasChild, permissions }: NavListRootProps) {
  const { pathname } = useLocation();

  const { user } = useAuthContext();

  const { themeLayout, onToggleLayout } = useSettingsContext();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = (itedata: any) => {
    setOpen(!open);

    if (
      (itedata?.path === PATH_DASHBOARD.cashierportal.new && themeLayout === 'vertical') ||
      themeLayout === 'horizontal'
    ) {
      onToggleLayout();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={() => handleToggle(data)}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList
            data={data.children.filter((list: any) =>
              user?.role === userRole.SystemAdmin
                ? list
                : (list.path !== PATH_DASHBOARD.manageStore.new && list.path !== PATH_DASHBOARD.general.booking)
            )}
            depth={depth}
            permissions={permissions}
          />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  permissions?: string[];
};

function NavSubList({ data, depth, permissions }: NavListSubProps) {
  return (
    <>
      {/* filter(item => permissions?.includes(item.title)) */}
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
}
