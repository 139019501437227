import { createSlice } from '@reduxjs/toolkit';
import { IFEEDBACKEVENTSELECT } from 'src/@types/FeedBackEventSelect';

const initialState: IFEEDBACKEVENTSELECT = {
    event: '',
};

const feedbackEventSelectReducerSlice = createSlice({
  name: 'feedbackEventSelectInfo',
  initialState,
  reducers: {
    // START LOADING
    setFeedbackEventSelectInfo(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFeedbackEventSelectInfo } = feedbackEventSelectReducerSlice.actions;

// Reducer
export default feedbackEventSelectReducerSlice;