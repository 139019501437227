import { useAuthContext } from 'src/auth/useAuthContext';
import { userRole } from 'src/utils/constants';

import ThemeContrast from './ThemeContrast';
import ThemeRtlLayout from './ThemeRtlLayout';
import ThemeColorPresets from './ThemeColorPresets';
import SettingsDrawer from './drawer';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  const { user } = useAuthContext();

  return (
    <ThemeColorPresets>
      <ThemeContrast>
        <ThemeRtlLayout>
          {children}
          {user?.role === userRole.SystemAdmin && <SettingsDrawer />}
        </ThemeRtlLayout>
      </ThemeContrast>
    </ThemeColorPresets>
  );
}