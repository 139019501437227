export function objectToKeyValueFormatter(obj: any) {
  const result = Object.keys(obj)
    ?.filter((key: string) => obj[key])
    .map((key: string) => ({
      key,
      value: obj[key],
    }));
  return result;
}

export function KeyValueToObjectFormatter(arr: any, type: string = 'arr') {
  if (!arr || arr.length === 0) return [];
  const result = arr.reduce(
    (acc: any, current: any) => ({
      ...acc,
      [current.key]: current.value || '',
    }),
    {}
  );
  if (type === 'obj') {
    return result;
  }
  return [result];
}

export const maskName = (name: string) => {
  if (!name) return '';
  let masked = name[0];
  
  if (name.length > 1) {
    masked += name[1];
    masked += '*'.repeat(name.length - 2);
  }
  return masked;
};

export const maskMobile = (name: string) => {
  // Check if the name exists and is long enough to mask
if (!name || name === null || name === undefined || name.length < 7) return name;

// Extract the first 3 characters
const start = name.substring(0, 3);

// Extract the last 3 characters
const end = name.substring(Math.max(name.length - 3, 0));

// Calculate the number of characters to mask
const maskLength = Math.max(name.length - 6, 0);

// Create the masked string
const masked = `${start}${'*'.repeat(maskLength)}${end}`;

return masked;
};
