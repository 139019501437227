import {
  format,
  getTime,
  formatDistanceToNow,
  subMonths,
  startOfYesterday,
  endOfYesterday,
  subDays,
  endOfDay,
  startOfDay,
} from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function isBetween(inputDate: Date | string | number, startDate: Date, endDate: Date) {
  const date = new Date(inputDate);

  const results =
    new Date(date.toDateString()) >= new Date(startDate.toDateString()) &&
    new Date(date.toDateString()) <= new Date(endDate.toDateString());

  return results;
}

export function isAfter(startDate: Date | null, endDate: Date | null) {
  const results =
    startDate && endDate ? new Date(startDate).getTime() > new Date(endDate).getTime() : false;

  return results;
}

export const checkSatrtDateCheck = (chartFilter: string, startDate?: any) => {
  const now = new Date();
  let sDate: any;

  switch (chartFilter) {
    case 'today':
      sDate = format(new Date(), 'dd-MM-yyyy');
      break;
    case 'yesterday': {
      // Get the start of yesterday
      sDate = format(startOfYesterday(), 'dd-MM-yyyy');
      break;
    }
    case 'last7days': {
      // Get the start of 7 days ago
      const sevenDaysAgo = subDays(now, 7);
      sDate = format(startOfDay(sevenDaysAgo), 'dd-MM-yyyy');
      break;
    }
    case 'last30days':
      sDate = format(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30), 'dd-MM-yyyy');
      break;
    case 'last60days':
      sDate = format(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 60), 'dd-MM-yyyy');
      break;
    case 'thismonth':
      sDate = format(new Date(now.getFullYear(), now.getMonth(), 1), 'dd-MM-yyyy');
      break;
    case 'lastmonth':
      sDate = format(new Date(now.getFullYear(), now.getMonth() - 1, 1), 'dd-MM-yyyy');
      break;
    case 'last3months':
      sDate = format(subMonths(now, 3), 'dd-MM-yyyy');
      break;
    case 'last6months':
      sDate = format(subMonths(now, 6), 'dd-MM-yyyy');
      break;
    case 'last12months':
      sDate = format(subMonths(now, 12), 'dd-MM-yyyy');
      break;
    case 'customrange':
      sDate = format(new Date(startDate), 'dd-MM-yyyy');
      break;
    default:
      // Handle unknown chartFilter values if needed
      break;
  }

  return sDate;
};

export const checkEndDateCheck = (chartFilter: string, endDate?: any) => {
  const now = new Date();
  let eDate: string;

  switch (chartFilter) {
    case 'today':
      // Get the end of today (23:59:59)
      eDate = format(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
        'dd-MM-yyyy'
      );
      break;
    case 'yesterday':
      eDate = format(endOfYesterday(), 'dd-MM-yyyy');
      break;
    case 'last7days': {
      // Get the date 7 days ago from today
      const sevenDaysAgo = subDays(now, 7);
      // Set the end date to the end of today
      eDate = format(endOfDay(now), 'dd-MM-yyyy');
      break;
    }
    case 'last30days':
      // End date is today for these filters
      eDate = format(now, 'dd-MM-yyyy');
      break;
    case 'thismonth':
      // Get the last day of the current month
      eDate = format(new Date(now.getFullYear(), now.getMonth() + 1, 0), 'dd-MM-yyyy');
      break;
    case 'lastmonth':
      // Get the last day of the last month
      eDate = format(new Date(now.getFullYear(), now.getMonth(), 0), 'dd-MM-yyyy');
      break;
    case 'last3months':
    case 'last6months':
    case 'last12months':
      // For last 3, 6, 12 months, the end date is still today
      eDate = format(now, 'dd-MM-yyyy');
      break;
    case 'customrange':
      eDate = endDate ? format(new Date(endDate), 'dd-MM-yyyy') : format(now, 'dd-MM-yyyy');
      break;
    default:
      // Handle unknown chartFilter values if needed
      eDate = format(now, 'dd-MM-yyyy');
      break;
  }

  return eDate;
};

// Function to compute start date
export const computeStartDate = (chartFilter: string, startDate: Date | string) => {
  if (chartFilter === 'customrange') {
    return startDate ? format(new Date(startDate), 'dd-MM-yyyy') : checkSatrtDateCheck(chartFilter);
  }
  return checkSatrtDateCheck(chartFilter); // Assuming checkStartDateCheck is a function you've defined
};

// Function to compute end date
export const computeEndDate = (chartFilter: string, endDate: Date | string) => {
  if (chartFilter === 'customrange') {
    return endDate ? format(new Date(endDate), 'dd-MM-yyyy') : checkEndDateCheck(chartFilter);
  }
  return checkEndDateCheck(chartFilter); // Assuming checkEndDateCheck is a function you've defined
};

export const campaignEndDate = (date: string) => {
  const dateTimeString = date;
  const [datePart, timePart]: any = dateTimeString.split(' ');
  const [day, month, year]: any = datePart.split('-');
  const [hour, minute, second]: any = timePart.split(':');
  const newDate = new Date(year, month - 1, day, hour, minute, second);
  return newDate;
};