// @mui
import { Divider, Stack, Typography } from '@mui/material';
// auth
// import { useAuthContext } from '../../../auth/useAuthContext';
// locales
// import { useLocales } from '../../../locales';
// routes
// import { PATH_DOCS } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function NavDocs() {
  // const { user } = useAuthContext();

  // const { translate } = useLocales();

  return (
    <>
      {/* <Stack
        spacing={3}
        sx={{
          px: 5,
          pb: 5,
          mt: 10,
          width: 1,
          display: 'block',
          textAlign: 'center',
        }}
      > */}
      {/* <Box component="img" src="/assets/illustrations/illustration_docs.svg" /> */}
      {/* <div> */}
      {/* <Typography gutterBottom variant="subtitle1">
          {`${translate('docs.hi')}, ${user?.displayName}`}
        </Typography> */}
      {/* </div> */}

      {/* <Button href={PATH_DOCS.root} target="_blank" rel="noopener" variant="contained">
        {`${translate('docs.documentation')}`}
      </Button> */}
      {/* </Stack> */}
      <Stack
        spacing={1}
        sx={{
          mt: 1,
          textAlign: 'center',
        }}
      >
        <Divider />
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          Need help? Give us a call
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          Tel:+91-9773121818 (11am-7pm)
        </Typography>
      </Stack>
    </>
  );
}