import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
import constants from 'src/utils/constants';

// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

function Login() {

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">{constants.login.loginSubTypography}</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{constants.login.typographies.body2}</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            {constants.login.typographies.subtitle2}
          </Link>
        </Stack>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}

export default React.memo(Login); 