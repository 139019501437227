import { createSlice } from '@reduxjs/toolkit';
// import { IUserAccount } from 'src/@types/user';

const initialState: any = {};

const cashierMessageReducerSlice = createSlice({
  name: 'cashierMessage',
  initialState,
  reducers: {
    // START LOADING
    setCashierMessage(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setCashierMessage } = cashierMessageReducerSlice.actions;

// Reducer
export default cashierMessageReducerSlice;
