import axios from '../utils/axios';

const createRequest = async ({
  headers = {},
  url,
  method = 'GET',
  data,
  params,
  responseType,
}: any) => {
  const config: any = {
    headers,
    url,
    method,
    data,
    params,
    responseType,
  };

  return axios.request(config);
};
export default createRequest;
