import React from 'react';
import {CheckboxProps, TextFieldProps, Checkbox } from '@mui/material';
// form
import { useFormContext, Controller } from 'react-hook-form';

interface CheckboxOption {
  label: string;
  value: string;
}

type CheckboxGroupProps = CheckboxProps &
  TextFieldProps & {
    options: CheckboxOption[];
    name: string;
  };

function RFMCheckboxGroup({ options, name }: CheckboxGroupProps) {
  const { control } = useFormContext();
  return (
    <>
      {options.map((option: CheckboxOption, index: number) => (
        <div key={index}>
          <Controller
            name={`${name}[${index}]`}
            control={control}
            defaultValue={false}
            render={({ field, fieldState: { error } }) => (
              <Checkbox {...field} checked={field.value} />
            )}
          />
          {option.label}
        </div>
      ))}
    </>
  );
}

export default RFMCheckboxGroup;