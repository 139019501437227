import { memo } from 'react';
import { Box, Stack } from '@mui/material';
import localStorageAvailable from 'src/utils/localStorageAvailable';
//
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

function NavSectionMini({ data, sx, ...other }: NavSectionProps) {
  const storageAvailable = localStorageAvailable();
  const user: any = storageAvailable ? JSON.parse(localStorage.getItem('userDetails') || '') : {};
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => (
        <Items
          key={index}
          items={group.items.filter((list: any) =>  user?.permissions.includes(list.title) && list)}
          // items={group.items.filter((list: any) => list)}
          isLastGroup={index + 1 === data.length}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
  isLastGroup: boolean;
};

function Items({ items, isLastGroup }: ItemsProps) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
}