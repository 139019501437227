// ----------------------------------------------------------------------

function path(root?: string, sublink?: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const USER = '/user';
const STORE = '/manage-store';
const ANALYTICS = '/analytics';
const OTHER = '/other';
const CAMPAIGN = '/campaigns';
const FEEDBACK_INSIGHT = '/feedback';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  loginWithOtp: path(ROOTS_AUTH, '/login-with-otp'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/admin'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/customer'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  workflow: {
    root: path(ROOTS_DASHBOARD, '/wf'),
    cust: path(ROOTS_DASHBOARD, '/wfcustomer'),
    // all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: USER,
    new: path(USER, '/new'),
    list: path(USER, '/list'),
    cards: path(USER, '/cards'),
    profile: path(USER, '/profile'),
    account: path(USER, '/account'),
    edit: (name: string) => path(USER, `/${name}/edit`),
    demoEdit: path(USER, `/reece-chung/edit`),
  },
  manageStore: {
    root: STORE,
    shop: path(STORE, '/shop'),
    list: path(STORE, '/list'),
    checkout: path(STORE, '/checkout'),
    new: path(STORE, '/new'),
    view: (name: string) => path(STORE, `/store/${name}`),
    edit: (name: string) => path(STORE, `/${name}/edit`),
    demoEdit: path(STORE, '/nike-blazer-low-77-vintage/edit'),
    demoView: path(STORE, '/nike-air-force-1-ndestrukt'),
  },
  analytics: {
    root: ANALYTICS,
    view: path(ANALYTICS, '/view'),
    report: path(ANALYTICS, '/reports'),
    // newreport: path(ANALYTICS, '/newreport'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  cashierportal: {
    root: path(ROOTS_DASHBOARD, '/cashier'),
    new: path(ROOTS_DASHBOARD, '/cashier/new-invoice'),
    rev: path(ROOTS_DASHBOARD, '/cashier/issue-reward'),
  },
  manageRequestportal: {
    root: path(ROOTS_DASHBOARD, '/request'),
    rev: path(ROOTS_DASHBOARD, '/request/redeem-request'),
    list: path(ROOTS_DASHBOARD, '/request/list-request'),
    submit: path(ROOTS_DASHBOARD, '/request/submit-request'),
    redeem: path(ROOTS_DASHBOARD, '/request/redeem-request'),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
  },
  other: {
    root: OTHER,
    import: path(OTHER, '/import'),
    history: path(OTHER, '/import/history'),
  },
  campaign: {
    root: CAMPAIGN,
  },
  feedback: {
    root: FEEDBACK_INSIGHT,
    insight: path(FEEDBACK_INSIGHT, '/insight'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';