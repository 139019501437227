import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// form
import { useFormContext, Controller } from 'react-hook-form';

interface PINCODE {
  name: string;
  label: string;
  option: any;
  selectSize?: 'small' | 'medium' | undefined;
  handleChange?: ((e: any, newValue: any) => void | {}) | any;
}

export default function AutoCompleteText({
  name,
  label,
  option,
  selectSize,
  handleChange,
}: PINCODE) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Autocomplete
          freeSolo
          autoComplete
          includeInputInList
          getOptionLabel={(optionlist: any) => optionlist?.label || optionlist}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          onInputChange={(event: any, newValue: any) =>
            setValue(name, newValue, { shouldValidate: true })
          }
          onSelect={handleChange}
          options={option}
          value={value?.label || value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              size={selectSize}
              value={typeof value === 'number' && value === 0 ? '' : value}
              helperText={error ? error?.message : ''}
            />
          )}
        />
      )}
    />
  );
}