import { createSelector } from '@reduxjs/toolkit';

const fetchingUIConfig = createSelector(
  (state: any) => state.landingParams.config,
  (config: any) => config
);

const getParams = createSelector(
  (state: any) => state.landingParams.commonQueryParams,
  (commonQueryParams: any) => commonQueryParams
);

const getMandatory = createSelector(
  (state: any) => state.landingParams.mandatoryColumns,
  (mandatoryColumns: any) => mandatoryColumns
);

const landingParamsSelector = {
  fetchingUIConfig,
  getParams,
  getMandatory,
};

export default landingParamsSelector;
