import { createSlice } from '@reduxjs/toolkit';
import { IRFMUSERINFO } from 'src/@types/rfm';

const initialState: IRFMUSERINFO = {
    clientCode: '',
    programIdentifier: '',
    storeCode: ''
};

const rfmUserInfoReducerSlice = createSlice({
  name: 'rfmuserInfo',
  initialState,
  reducers: {
    // START LOADING
    setRfmUserInfo(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setRfmUserInfo } = rfmUserInfoReducerSlice.actions;

// Reducer
export default rfmUserInfoReducerSlice;
