import _mock from '../_mock';

// ----------------------------------------------------------------------

export const _bankingContacts = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  avatar: _mock.image.avatar(index),
}));

export const _bankingCreditCard = [
  {
    id: _mock.id(2),
    balance: 300.50,
    cardType: 'mastercard',
    cardHolder: 'Mr. John Doe [Gold Tier]',
    cardNumber: '**** **** **** 3640',
    cardValid: '04/23',
  },
  {
    id: _mock.id(3),
    balance: 199.50,
    cardType: 'visa',
    cardHolder: _mock.name.fullName(3),
    cardNumber: '**** **** **** 8864',
    cardValid: '04/23',
  },
  {
    id: _mock.id(4),
    balance: 100.00,
    cardType: 'visa',
    cardHolder: _mock.name.fullName(4),
    cardNumber: '**** **** **** 7755',
    cardValid: '04/23',
  },
];
export const _bankingRecentTransitions = [
  {
    id: _mock.id(2),
    name: _mock.name.fullName(2),
    avatar: _mock.image.avatar(8),
    type: 'Income',
    message: 'Shopping at',
    category: 'Store Code 3451',
    date: 1682008485000,
    amount: 2800.00,
    point: 28,
  },
  {
    id: _mock.id(3),
    name: _mock.name.fullName(3),
    avatar: _mock.image.avatar(9),
    type: 'Income',
    message: 'Shopping at',
    category: 'Store Code 3451',
    date: 1682008485000,
    point: 24.36,
    amount: 2436.00,
  },
  {
  id: _mock.id(4),
  name: _mock.name.fullName(4),
  avatar: _mock.image.avatar(12),
  type: 'Expenses',
  message: 'Points Redeemed',
  category: 'Shopping',
  date: 1681922085000,
  point: -200.00,
  amount: 1000.00,
},
{
  id: _mock.id(5),
  name: null,
  avatar: null,
  type: 'Income',
  message: 'Referral for 9099063934',
  category: 'Campaign Reward',
  date: 1681835685000,
  point: 100.00,
  amount: 0.00,
},
{
  id: _mock.id(6),
  name: null,
  avatar: null,
  type: 'Income',
  message: 'Signup',
  category: 'Campaign Reward',
  date: 1681735685000,
  point: 100.00,
  amount: 0.00,
},
];
