import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue =  any;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue, check?: string) {
  const format = number ? numeral(number).format('0,0.00') : '';
  if(check === 'two'){
    return parseFloat(number).toFixed(2);
  }
  if(check === 'yes'){
    return number;
  }
  return result(format, '.00');
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue, type?:string) {
  const format = number ? numeral(number).format('0.00a') : '';
  if(type === 'chart'){
    return numeral(number).format('0');
  }
  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
