import { createSlice } from '@reduxjs/toolkit';
import { IRFMUSERINFO } from 'src/@types/rfm';

const initialState: IRFMUSERINFO = {
    clientCode: '',
    programIdentifier: '',
    storeCode: ''
};

const adminDashboardReducerSlice = createSlice({
  name: 'adminDashboardInfo',
  initialState,
  reducers: {
    // START LOADING
    setAdminDashboardInfo(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setAdminDashboardInfo } = adminDashboardReducerSlice.actions;

// Reducer
export default adminDashboardReducerSlice;
