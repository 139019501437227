import { createSlice } from '@reduxjs/toolkit';

export type ILandingState = {
  error: Error | string | null;
  commonQueryParams?: {
    clientCode?: string | null;
    programIdentifier?: string | null;
    serviceName?: string | null;
    channelType?: string;
    channelName?: string;
    userName: string | null;
  };
  config?: Object;
  mandatoryColumns: any;
};

const initialState: ILandingState = {
  config: {},
  error: null,
  commonQueryParams: {
    clientCode: '',
    programIdentifier: '',
    channelType: 'WEB',
    channelName: 'Internal Portal',
    userName:''
  },
  mandatoryColumns: [],
};

const landingParamsReducerSlice = createSlice({
  name: 'landingParams',
  initialState,
  reducers: {
    // START LOADING
    setUIConfig(state, action) {
      return { ...state, config: { ...action.payload } };
    },
    hasErrorFetchingUIConfig(state, action) {
      return { ...state, error: action.payload };
    },
    // GET BOARD
    setParams(state, action) {
      return { ...state, commonQueryParams: { ...state.commonQueryParams, ...action.payload } };
    },
    setMandatoryFields(state, action) {
      return { ...state, mandatoryColumns: [...action.payload] };
    },
  },
});

export const { setUIConfig, setParams, hasErrorFetchingUIConfig, setMandatoryFields } =
  landingParamsReducerSlice.actions;

// Reducer
export default landingParamsReducerSlice;
