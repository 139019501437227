import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// form
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { RightIcon } from '../carousel/Icon';

interface Props {
  name: string;
  label: string;
  option: any;
  selectSize?: 'small' | 'medium' | undefined;
  handleSelect?: ((e: React.ChangeEvent<HTMLInputElement>) => void | {}) | any
}

export default function AutoCompleteForNameAndMobile({ name, label, option, selectSize,handleSelect }: Props) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <Autocomplete
          getOptionLabel={(optionlist: any) => optionlist?.instrumentValue || optionlist}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          onInputChange={(event: any, newValue: any) =>
            setValue(name, newValue, { shouldValidate: true })
          }
          onSelect={(event) => handleSelect(event)}
          options={option}
          value={value?.label || value}
          renderOption={(props, list) => (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {list.firstName} {list.lastName}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {list.instrumentValue}
                  </Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <RightIcon />
                </Grid>
              </Grid>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              size={selectSize}
              helperText={error ? error?.message : ''}
            />
          )}
        />
      )}
    />
  );
}