import React, { useCallback, useState } from 'react';
import endpoints from 'src/utils/endpoints';
// form
import RHFAutocomplete from './RHFAutocomplete';

import axios from '../../utils/axios';
import { KeyValueToObjectFormatter } from '../../utils/commonUtils';

interface Props {
  name: string;
  label: string;
  pinCodeoption?: string[];
  pinCodeValue?: string;
  setPinCodeValue: (value: string) => void;
  setPincodeOption: (value: string[]) => void;
  setPincode: (value: string[]) => void;
  handlsetValue: (value: string) => void
}

export default function AutocompleteFC({name, label, pinCodeoption, pinCodeValue,setPinCodeValue,setPincodeOption,setPincode, handlsetValue }: Props) {

  const handlChangePinCode = useCallback(
    async (event: React.SyntheticEvent<Element, Event>, value: any) => {
      if(/^\d*[.]?\d*$/.test(value) && !/^\d{7}$/.test(value)){
        setPinCodeValue(value);
        handlsetValue(value);
      if (value?.length > 3 && value?.length <= 6) {
        try {
          const req = {
            clientCode: null,
            programIdentifier: 'TBP',
            channelType: 'WEB',
            channelName: 'Internal Portal',
            serviceName: 'GET_PINCODE_LIST',
            resultSize: 10,
          };
          const sendReq: any = { ...req, prefix: value };
          const response = await axios.post(endpoints.getPinCodeList, sendReq);
          setPincodeOption(response.data.pincodeList);
        } catch (error) {
          console.log('error', error);
        }
      }
      if (value?.length > 5 && value?.length <= 6) {
        try {
          const req = {
            clientCode: null,
            programIdentifier: null,
            channelType: 'WEB',
            channelName: 'Internal Portal',
            serviceName: 'GET_GEOGRAPHY_BY_PINCODE',
          };
          const sendReq = { ...req, pincode: value };
          const response = await axios.post(endpoints.getGeographyByPincode, sendReq);
          const check = KeyValueToObjectFormatter(response?.data?.data);
          setPincode(check);
        } catch (error) {
          console.log('error', error);
        }
      }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pinCodeValue]
  );

  return (
    <RHFAutocomplete
      name={name}
      label={label}
      options={pinCodeoption || []}
      getOptionLabel={(list: any) => list.toString()}
      multiple={false}
      value={pinCodeValue || ''}
      inputValue={pinCodeValue?.toString() || ''}
      onInputChange={(event, value) => handlChangePinCode(event, value)}
      onChange={(event, value) => handlChangePinCode(event, value)}
    />
  );
}