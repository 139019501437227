import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  tableData: [],
  mobileNo: ''
};

const userListReducerSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    setUserList(state, { payload }) {
      return { ...state, tableData: [...payload] };
    },
    setUserMobileNo(state, { payload }) {
      return { ...state, mobileNo: payload };
    },
  },
});

export const { setUserList, setUserMobileNo } = userListReducerSlice.actions;

// Reducer
export default userListReducerSlice;
