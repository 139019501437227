import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {};

const campaignReducerSlice = createSlice({
    name: 'campaignListId',
    initialState,
    reducers: {
      // START LOADING
      setCampaignListId(state, action) {
        return { ...state, ...action.payload };
      },
    },
  });
  
  export const { setCampaignListId } = campaignReducerSlice.actions;
  
  // Reducer
  export default campaignReducerSlice;