// ----------------------------------------------------------------------

export const phoneNumber = [
  '+91 61279 83960',
  '+91 83197 74187',
  '+91 61279 77897',
  '+91 89174 04053',
  '+91 82175 75427',
  '+91 61279 31809',
  '+91 79797 89699',
  '+91 61279 13137',
  '+91 788 761 4476',
  '+91 65104 67484',
  '+91 61279 15849',
  '+91 89176 60988',
  '+91 66398 10413',
  '+91 61279 10596',
  '+91 61279 02279',
  '+91 73174 61356',
  '+91 89177 11216',
  '+91 75103 43609',
  '+91 82076 19271',
  '+91 61279 90438',
  '+91 81675 08134',
  '+91 68001 78600',
  '+91 89126 36226',
  '+91 61279 22794',
  '+91 74470 24955',
  '+91 72878 92550',
  '+91 61279 12087',
  '+91 80794 79451',
  '+91 74473 60546',
  '+91 61279 54796',
  '+91 61279 07030',
  '+91 61279 88908',
  '+91 74770 26947',
  '+91 61279 31364',
  '+91 61279 50457',
  '+91 88475 60234',
  '+91 60385 47854',
  '+91 80790 38991',
  '+91 88481 91944',
  '+91 61279 09679',
  '+91 89121 81788',
  '+91 61279 00483',
  '+91 67400 12225',
  '+91 61279 30781',
  '+91 61279 12329',
  '+91 72325 73941',
  '+91 61279 28041',
  '+91 80745 26208',
  '+91 61279 58304',
  '+91 83888 49589',
];
