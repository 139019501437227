import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import landingParamsReducerSlice from './slices/landingParams';
import userListReducerSlice from './slices/userList';
import userInfoReducerSlice from './slices/userInfo';
import cashierMessageReducerSlice from './slices/cashier';
import campaignReducerSlice from './slices/campaign';
import rfmUserInfoReducerSlice from './slices/rfm';
import adminDashboardReducerSlice from './slices/adminDashboard';
import feedbackEventSelectReducerSlice from './slices/FeedBackEventSelect';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'store',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  store: persistReducer(productPersistConfig, productReducer),
  landingParams: landingParamsReducerSlice.reducer,
  userList: userListReducerSlice.reducer,
  userInfo: userInfoReducerSlice.reducer,
  cashierMessage: cashierMessageReducerSlice.reducer,
  campaignListId: campaignReducerSlice.reducer,
  rfmUserInfo: rfmUserInfoReducerSlice.reducer,
  adminDashboardInfo: adminDashboardReducerSlice.reducer,
  feedbackEeventSelect: feedbackEventSelectReducerSlice.reducer
});

export default rootReducer;
